




import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import * as echarts from 'echarts'
@Component
export default class VideoView extends Vue {
  @Prop({ default: {} }) option!: object
  chart: any
  mounted() {
    this.renderChart()
  }
  @Watch('option', { deep: true })
  getOption(val: any) {
    this.chart.setOption(val)
  }

  renderChart() {
    // 基于准备好的dom，初始化echarts实例
    if (this.chart != null && this.chart != '' && this.chart != undefined) {
      this.chart.dispose()
    }
    const chartDom = document.getElementById('chartDom')
    this.chart = echarts.init(chartDom as HTMLElement)
    this.chart.setOption(this.option)
  }
}
