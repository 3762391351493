








































































































































































































/* eslint-disable @typescript-eslint/no-var-requires */
import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import 'animate.css'
import { getDicts, getDictName } from '@/utils/dict'
// import BMapGL from 'BMapGL'
// import Player from 'xgplayer'
import Table from '@/components/Table/index.vue'
import Chart from '@/components/Charts/index.vue'
import * as echarts from 'echarts'
import { Avatar, DatePicker } from 'element-ui'
Vue.use(Avatar)
Vue.use(DatePicker)

// 地图点位图标
const greenDefault = require('@/assets/images/map_green_default.png')
const greenChoosed = require('@/assets/images/map_green_choosed.png')
const orangeDefault = require('@/assets/images/map_orange_default.png')
const orangeChoosed = require('@/assets/images/map_orange_choosed.png')

@Component({
  components: {
    Table,
    Chart
  }
})
export default class Home extends Vue {
  icon1 = {
    color: '#8D8D8D'
  }
  $constants: any
  // 所有设备组
  allEquipGroup: any = []
  // user
  user: any = {}
  // 门禁权限
  userEquipGroupLimit: any = []
  // 访客进出记录概览
  faceRecordCounts: any = []
  // 车辆进出记录概览
  carCounts: any = []
  optionMonth1 = ''
  optionMonth2 = ''
  startMonth1 = ''
  startMonth2 = ''
  endMonth1 = ''
  endMonth2 = ''
  tabActive = 0
  deviceGroupId = ''
  // socket配置
  websock: any = null
  reconnectData: any = null
  serverTimeoutObj: any = null
  lockReconnect = false //避免重复连接，因为onerror之后会立即触发 onclose
  timeout = 30000 //30s一次心跳检测
  timeoutObj: any = null
  // websocketTestUrl = 'ws://community-dev.jiketravel.com/service/c-pc/ws/index'
  // 开发环境
  websocketTestUrl = 'wss://community-test.jiketravel.com/api/c-pc/ws/index'
  // websocketTestUrl = 'ws://community-test.jiketravel.com/api/c-pc/ws/index'
  // 李本地
  // websocketTestUrl = 'ws://10.0.10.109:8021/service/c-pc/ws/index'
  // 生产环境
  websocketProductUrl = 'wss://community.luopan88.com/api/c-pc/ws/index'
  // 人脸记录
  faceRecordList: any = []
  faceTypeDicts: any = []
  defaultFemaleIcon = 'this.src="' + require('@/assets/images/female.png') + '"'
  defaultmaleIcon = 'this.src="' + require('@/assets/images/male.png') + '"'
  openTypeDicts: any = []
  // 车闸处理
  isCarShow = false
  carShowLists = [] as any
  carShowInterval: any = null
  // 车闸记录
  carShowInfo = {} as any
  carRecordList: any = []
  carTypeDicts = [] as any
  deviceTypeDicts = [] as any
  carDirectionDicts = [] as any
  deviceLists = [] as any
  // 表格是否需要序号
  isNeedId = false
  tableDoorHeader = [
    {
      key: 'faceImg',
      name: '照片',
      width: '14%',
      isSpecial: true
    },
    {
      key: 'devGroupName',
      name: '位置',
      width: '18%'
    },
    {
      key: 'personType',
      name: '人员',
      width: '12%',
      isSpecial: true
    },
    {
      key: 'snapTime',
      name: '时间',
      width: '26%'
    },
    {
      key: 'openType',
      name: '进出类型',
      width: '',
      isSpecial: true
    }
  ]
  tableBrakeHeader = [
    {
      key: 'carNo',
      name: '车牌',
      width: '25%'
    },
    {
      key: 'door',
      name: '位置',
      width: '25%'
    },
    {
      key: 'houseNo',
      name: '所属房屋',
      width: '25%',
      isSpecial: true
    },
    {
      key: 'direction',
      name: '进出类型',
      width: '25%',
      isSpecial: true
    }
  ]
  pickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now()
    }
  }
  // 折线图配置
  chart2: any
  chartOption = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      // data: ['进入', '出']
      data: ['进入']
    },
    toolbox: {
      feature: {}
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30'
      ]
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%']
    },
    dataZoom: [
      {
        type: 'inside',
        startValue: moment().date() <= 7 ? 0 : moment().date() - 7,
        endValue:
          moment().date() <= 7 ? moment().date() - 1 : moment().date() - 1
      },
      {
        startValue: moment().date() <= 7 ? 0 : moment().date() - 7,
        endValue:
          moment().date() <= 7 ? moment().date() - 1 : moment().date() - 1
      }
    ],
    series: [
      {
        name: '进入',
        type: 'line',
        smooth: true,
        showSymbol: false,
        // symbolSize: 3,
        // symbol: 'pin',
        itemStyle: {
          color: '#89A1DC'
        },
        lineStyle: {
          width: 2
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 0.8, [
            {
              offset: 0,
              color: '#D7EBF5'
            },
            {
              offset: 1,
              color: 'rgba(255,255,255)'
            }
          ])
        },
        data: [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ]
      }
      // {
      //   name: '出',
      //   type: 'line',
      //   itemStyle: {
      //     color: '#8EB03D'
      //   },
      //   areaStyle: {
      //     color: new echarts.graphic.LinearGradient(0, 0, 0, 0.8, [
      //       {
      //         offset: 0,
      //         color: '#E9F7DF'
      //       },
      //       {
      //         offset: 1,
      //         color: 'rgba(255,255,255)'
      //       }
      //     ])
      //   },
      //   smooth: true,
      //   showSymbol: false,
      //   data: [
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0,
      //     0
      //   ]
      // }
    ]
  }
  chartOption2 = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['进入', '出']
    },
    toolbox: {
      feature: {}
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30'
      ]
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%']
    },
    dataZoom: [
      {
        type: 'inside',
        startValue: moment().date() <= 7 ? 0 : moment().date() - 7,
        endValue:
          moment().date() <= 7 ? moment().date() - 1 : moment().date() - 1
      },
      {
        startValue: moment().date() <= 7 ? 0 : moment().date() - 7,
        endValue:
          moment().date() <= 7 ? moment().date() - 1 : moment().date() - 1
      }
    ],
    series: [
      {
        name: '进入',
        type: 'line',
        itemStyle: {
          color: '#E77151'
        },
        showSymbol: false,
        data: [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ]
      },
      {
        name: '出',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#89A1DC'
        },
        data: [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ]
      }
    ]
  }

  async beforeCreate() {
    const BMapGL = (window as any).BMapGL
    if (!BMapGL) {
      window.location.reload()
    }
    // 获取相关字典
    // 设备类型
    this.deviceTypeDicts = await getDicts('DEVICE_TYPE')
    this.carTypeDicts = await getDicts('CAR_TYPE')
    this.faceTypeDicts = await getDicts('FACE_LOG_PERSON_TYPE')
    this.openTypeDicts = await getDicts('FACE_LOG_OPEN_TYPE')
    this.carDirectionDicts = await getDicts('CAR_BRAKE_DIRECTION')
    this.getAllEquipGroup()
  }
  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, '' + val)
  }

  destroyed() {
    if (
      this.user &&
      this.userEquipGroupLimit &&
      this.userEquipGroupLimit.length > 0
    ) {
      this.lockReconnect = true
      this.websock.close() //离开路由之后断开websocket连接
      clearTimeout(this.reconnectData) //离开清除 reconnectData
      clearTimeout(this.timeoutObj) //离开清除 timeoutObj
      clearTimeout(this.serverTimeoutObj) //离开清除 serverTimeoutObj
    }
  }

  mounted() {
    const user = sessionStorage.getItem('user')
    if (!user) {
      this.$router.push('/')
      sessionStorage.clear()
      localStorage.clear()
    } else {
      this.user = user && JSON.parse(user)
      this.userEquipGroupLimit = user && JSON.parse(user).deviceGroupIds
      // setInterval(() => {
      //   this.getTime()
      // }, 1000)
      this.renderChart()
      this.getEquipmentLists()
      this.carShowLists = []
      if (this.userEquipGroupLimit.length > 0) {
        this.initWebSocket()
        this.getCarRecordList('new')
        this.getFaceRecordList()
      }
    }
  }

  getTime() {
    this.optionMonth1 = moment().format('YYYY-MM-DD HH:mm:ss')
    this.optionMonth2 = moment().format('YYYY-MM-DD HH:mm:ss')
    this.startMonth1 = moment()
      .startOf('month')
      .format('YYYY-MM-DD')
    this.endMonth1 = moment()
      .endOf('month')
      .format('YYYY-MM-DD')
    this.startMonth2 = this.startMonth1
    this.endMonth2 = this.endMonth1
    this.getCarCount(this.userEquipGroupLimit.toString())
    this.getFaceRecordCount(this.userEquipGroupLimit.toString())
  }

  monthchane(e: any) {
    this.startMonth1 = moment(e)
      .startOf('month')
      .format('YYYY-MM-DD')
    this.endMonth1 = moment(e)
      .endOf('month')
      .format('YYYY-MM-DD')
    if (this.tabActive === 0) {
      this.getFaceRecordCount(this.userEquipGroupLimit.toString())
    } else {
      this.getFaceRecordCount(this.deviceGroupId)
    }
  }
  monthchane2(e: any) {
    this.startMonth2 = moment(e)
      .startOf('month')
      .format('YYYY-MM-DD')
    this.endMonth2 = moment(e)
      .endOf('month')
      .format('YYYY-MM-DD')
    if (this.tabActive === 0) {
      this.getCarCount(this.userEquipGroupLimit.toString())
    } else {
      this.getCarCount(this.deviceGroupId)
    }
    // this.getCarCount()
  }
  renderChart() {
    if (this.chart2 != null && this.chart2 != '' && this.chart2 != undefined) {
      this.chart2.dispose()
    }
    const chartDom2 = document.getElementById('chartDom2')
    this.chart2 = echarts.init(chartDom2 as HTMLElement)
    this.chart2.setOption(this.chartOption2)
  }

  baiduMap() {
    const BMapGL = (window as any).BMapGL
    const map = new BMapGL.Map('map')
    // 地图中心点
    const point = new BMapGL.Point(120.626999, 31.332664)
    map.centerAndZoom(point, 18)
    // map.addOverlay(new BMapGL.Marker(point))
    map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
    // 地图点坐标渲染
    this.deviceLists.map((v: any) => {
      const myIcon = new BMapGL.Icon(
        v.status === 1 ? greenDefault : orangeDefault,
        new BMapGL.Size(34, 34)
      )
      const point = new BMapGL.Point(v.lng, v.lat)
      // 创建标注对象并添加到地图
      const marker = new BMapGL.Marker(point, { icon: myIcon })
      map.addOverlay(marker)
      marker.addEventListener('click', () => {
        const opts = {
          title: this.filterDictName(this.deviceTypeDicts, v.type)
        }
        const text =
          '<div>设备名称：' +
          v.deviceName +
          '</div>' +
          '<div>型号：' +
          v.model +
          '</div>' +
          '<div>状态：' +
          (v.status === 1 ? '启用' : '关闭') +
          '</div>'
        const infoWindow = new BMapGL.InfoWindow(text, opts)
        marker.setIcon(
          new BMapGL.Icon(
            v.status === 1 ? greenChoosed : orangeChoosed,
            new BMapGL.Size(34, 34)
          )
        )
        map.openInfoWindow(infoWindow, point)
      })
    })
  }

  // 获取所有设备组
  getAllEquipGroup() {
    this.$api.equipment.getDeviceGroups({ size: '-1' }).then((res: any) => {
      const data = res.data
      if (data.success) {
        if (this.userEquipGroupLimit.length === 0) {
          this.allEquipGroup = []
        } else {
          data.data.forEach((item: any) => {
            if (
              this.userEquipGroupLimit.findIndex((e: any) => e === item.id) !==
              -1
            ) {
              this.allEquipGroup.push(item)
            }
          })
          this.allEquipGroup.unshift({ id: '-1', name: '全部' })
        }
        this.getTime()
      }
    })
  }

  getFaceRecordCount(id?: any) {
    const p = {
      deviceGroupIds: id || '',
      startDate: this.startMonth1,
      endDate: this.endMonth1
    }
    if (p.deviceGroupIds) {
      this.$api.home.getFaceRecordCount(p).then((res: any) => {
        const data = res.data
        if (data.success) {
          if (data.data && data.data.DATE) {
            this.faceRecordCounts = data.data
            const days: any = []
            this.faceRecordCounts.DATE.forEach((day: any) => {
              days.push(day.substring(day.length - 2))
            })
            this.chartOption.series[0].data = this.faceRecordCounts.IN
            // this.chartOption.series[1].data = this.faceRecordCounts.OUT
            this.chartOption.xAxis.data = days
          }
        }
      })
    }
  }

  getCarCount(id?: any) {
    const p = {
      deviceGroupIds: id || '',
      startDate: this.startMonth2,
      endDate: this.endMonth2
    }
    if (p.deviceGroupIds) {
      this.$api.home.getCarCount(p).then((res: any) => {
        if (res.data.success) {
          this.carCounts = res.data.data
          const incar: any = []
          const outcar: any = []
          const days: any = []
          this.carCounts.forEach((item: any) => {
            incar.push(item.outCount)
            outcar.push(item.inCount)
            days.push(item.date.substring(item.date.length - 2))
          })
          this.chartOption2.series[0].data = incar
          this.chartOption2.series[1].data = outcar
          this.chartOption2.xAxis.data = days
          this.chart2.setOption(this.chartOption2)
        }
      })
    }
  }
  getEquipmentLists() {
    this.$api.equipment.getEquipmentList({ size: '-1' }).then((res: any) => {
      if (res.data.success) {
        this.deviceLists = res.data.data
        this.baiduMap()
      }
    })
  }

  getCarRecordList(type?: any) {
    if (this.userEquipGroupLimit.length > 0) {
      this.$api.home
        .getCarRecordList({
          size: 4,
          current: 1,
          devGroupIds: this.userEquipGroupLimit.toString()
        })
        .then((res: any) => {
          if (res.data.success) {
            this.carRecordList = res.data.data
            if (type && type !== 'socket') {
              // this.carRecordList.forEach((item: any) => {
              //   if (
              //     item.status === 0 &&
              //     item.resType !== 0 &&
              //     item.direction !== 2
              //   ) {
              //     item.snapTime = moment(item.time).format(
              //       'yyyy-MM-DD HH:mm:ss'
              //     )
              //     if (item.resType === 1) {
              //       item.remark = '车辆已到期！'
              //     } else if (item.resType === 2) {
              //       item.remark = '车位已满！'
              //     }
              //     this.carShowLists.push(item)
              //   }
              // })
              // if (this.carShowLists.length > 0) {
              //   this.isCarShow = true
              // }
            }
          }
        })
    }
  }

  getFaceRecordList() {
    if (this.userEquipGroupLimit.length > 0) {
      this.$api.home
        .getFaceRecordList({
          size: 4,
          current: 1,
          devGroupIds: this.userEquipGroupLimit.toString()
        })
        .then((res: any) => {
          if (res.data.success) {
            this.faceRecordList = res.data.data
          }
        })
    }
  }

  submitCar(car: any) {
    console.log('放行car', car)
    const p1 = {
      dataId: car.id,
      remark: car.remark,
      status: '1',
      userId: this.user.id
    }
    this.$api.home.checkCar(p1).then((res: any) => {
      if (res.data.success) {
        this.carShowLists.shift()
        this.isCarShow = false
        // const t = setInterval(() => {
        //   if (this.carShowLists.length > 0) {
        //     this.isCarShow = true
        //   }
        //   clearInterval(t)
        // }, 1000)
      } else if (res.data.code === 'S1003') {
        this.carShowLists.shift()
        this.isCarShow = false
        // const t = setInterval(() => {
        //   if (this.carShowLists.length > 0) {
        //     this.isCarShow = true
        //   }
        //   clearInterval(t)
        // }, 1000)
      }
    })
  }

  refuseCar(car: any) {
    console.log('拒绝car', car)
    const p2 = {
      dataId: car.id,
      remark: car.remark,
      status: '2',
      userId: this.user.id
    }
    this.$api.home.checkCar(p2).then((res: any) => {
      if (res.data.success) {
        this.carShowLists.shift()
        this.isCarShow = false
        // const t = setInterval(() => {
        //   if (this.carShowLists.length > 0) {
        //     this.isCarShow = true
        //   }
        //   clearInterval(t)
        // }, 1000)
      }
    })
  }

  // 跳转到进出记录
  go2VisitorList() {
    this.$router.push('/visitors/visitor-list')
  }

  tabChange(index: number, item: any) {
    if (item.id !== '-1') {
      this.deviceGroupId = item.id
      this.getCarCount(item.id)
      this.getFaceRecordCount(item.id)
    } else if (item.id === '-1') {
      this.getCarCount(this.userEquipGroupLimit.toString())
      this.getFaceRecordCount(this.userEquipGroupLimit.toString())
    }
    this.tabActive = index
  }

  //初始化weosocket
  initWebSocket() {
    // 获取当前页面地址
    const wPath = window.document.location.href
    console.log(
      'wPath',
      wPath,
      wPath.includes('localhost'),
      window.document.location.host
    )
    console.log('启动中')
    if (wPath.includes('localhost')) {
      this.websock = new WebSocket(this.websocketTestUrl)
      console.log('socket连接地址', this.websocketTestUrl)
    } else {
      this.websocketProductUrl =
        'wss://' + window.document.location.host + '/api/c-pc/ws/index'
      this.websock = new WebSocket(this.websocketProductUrl)
      console.log('socket连接地址', this.websocketProductUrl)
    }
    this.websock.onopen = this.websocketonopen //连接成功
    this.websock.onmessage = this.websocketonmessage //广播成功
    this.websock.onerror = this.websocketonerror //连接断开，失败
    this.websock.onclose = this.websocketclose //连接关闭
  }
  websocketonopen() {
    console.log('wsDevice连接成功')
    this.websocketsend()
    this.heatBeat()
  }

  websocketsend() {
    if (this.userEquipGroupLimit.length > 0) {
      const user = sessionStorage.getItem('user')
      const userId = user && JSON.parse(user).id
      const token = sessionStorage.getItem('token')
      const message = {
        msgType: 'Index_Subscribe_Devices',
        msg: {
          userId: userId,
          deviceGroupIds: this.userEquipGroupLimit
        },
        token: token
      }
      // 向后端发送数据
      console.log('wsDevice向后端发送数据', message)
      this.websock.send(JSON.stringify(message))
    }
  }

  websocketonerror(e: any) {
    console.log('wsDevice连接发生错误', e)
    // 重新连接
    this.reconnect()
  }
  websocketonmessage(e: any) {
    this.heatBeat()
    const data = JSON.parse(e.data)
    console.log('wsDevice推送的数据====', data)
    if (data.code === 'Index_Car_Travel') {
      this.getCarRecordList('socket')
      if (this.tabActive === 0) {
        this.getCarCount(this.userEquipGroupLimit.toString())
      } else {
        this.getCarCount(this.deviceGroupId)
      }
      // if (data.data.resType !== 0 && data.data.direction !== 2) {
      //   data.data.snapTime = moment(data.data.time).format(
      //     'yyyy-MM-DD HH:mm:ss'
      //   )
      //   if (data.data.resType === 1) {
      //     data.data.remark = '车辆已到期！'
      //   } else if (data.data.resType === 2) {
      //     data.data.remark = '车位已满！'
      //   }
      //   this.carShowLists.push(data.data)
      //   if (!this.isCarShow) {
      //     this.isCarShow = true
      //   }
      // }
    } else if (data.code === 'Index_Face_Log') {
      this.getFaceRecordList()
    }
  }
  websocketclose(e: any) {
    console.log('wsDevice断开连接', e)
    // 重新连接
    this.reconnect()
  }
  reconnect() {
    if (this.lockReconnect) {
      return
    }
    this.lockReconnect = true
    this.reconnectData && clearTimeout(this.reconnectData)
    console.log('wsDevice重新连接')
    this.reconnectData = setTimeout(() => {
      this.initWebSocket()
      this.lockReconnect = false
    }, 4000)
  }
  heatBeat() {
    this.timeoutObj && clearTimeout(this.timeoutObj)
    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj)
    this.timeoutObj = setTimeout(() => {
      //这里发送一个心跳，后端收到后，返回一个心跳消息
      const user = sessionStorage.getItem('user')
      const userId = user && JSON.parse(user).id
      const token = sessionStorage.getItem('token')
      const message = {
        msgType: 'Index_Subscribe_Devices',
        msg: {
          userId: userId,
          deviceGroupIds: this.userEquipGroupLimit
        },
        token: token
      }
      console.log('wsDevice心跳检测，向后端发送数据', message)
      this.websock.send(JSON.stringify(message))
      this.serverTimeoutObj = setTimeout(() => {
        this.websock.close() //如果  5秒之后我们没有收到 后台返回的心跳检测数据 断开socket，断开后会启动重连机制
      }, 5000)
    }, this.timeout)
  }
}
